import React, { useState, useEffect } from 'react';
import { PlayIcon } from '@heroicons/react/solid'
import useActiveVoice from 'hooks/useActiveVoice';
import useStyling from 'hooks/useStyling';
import { Voice as VoiceElement } from 'types/Voice';
import {getEnv} from "../../common/EnvUtil";

export interface VoiceProps extends React.HTMLAttributes<HTMLElement> {
	voice: VoiceElement,
	selectable?: boolean,
	isSelected?: boolean,
	onClick: ( event: React.MouseEvent) => void
	onChange?: ( selected ) => void
}

const Voice: React.FC<VoiceProps> = (
	{
		voice,
		selectable = false,
		isSelected = false,
		onClick,
		onChange

	}: VoiceProps ) => {
	const { REACT_APP_BASE_IMAGE_URL } = getEnv()
	const { switchClassNames } = useStyling()
	const activeVoice = useActiveVoice()

	const [isActive, setIsActive] = useState<boolean>( activeVoice?.id === voice?.id ? true : false )
	const [selected, setSelected] = useState<boolean>( isSelected )

	useEffect( () => {
		setIsActive( activeVoice?.id === voice?.id ? true : false )

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeVoice] )

	useEffect( () => {
		if ( onChange ) onChange( selected )
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected] )


	return (
		<button className="" onClick={onClick}>


				<div className={switchClassNames( isActive ?
						'border-cobalt-500' :
						'border-gray-300',
					'relative h-full p-2 overflow-hidden transition-all duration-1000 bg-gray-100 border-2 rounded-lg cursor-pointer group hover:border-cobalt-500' )}>

				<div className="absolute inset-0 z-0 transition-transform duration-1000 ease-in-out origin-center transform-gpu group-hover:scale-105 rounded-lg" >
					<img src={voice?.image ? voice?.image : "/assets/images/blank-voice-over-picture.png"} className="object-cover w-full h-full rounded-lg" alt={voice?.name} />
				</div>

				<div className="absolute inset-0 z-0 transition-opacity duration-300 ease-in-out group-hover:opacity-50 opacity-30 hover:opacity-50 bg-gradient-to-b from-transparent to-black overflow-hidden rounded-lg"></div>

				<div className={switchClassNames( isActive ?
					'opacity-100' :
					'opacity-70',
					'absolute inset-0 z-0 flex flex-col items-end justify-end transition-opacity duration-200 ease-in-out group-hover:opacity-100 text-white group-hover:text-cobalt-500 rounded-lg' )} >
					<PlayIcon className={switchClassNames( isActive ? ' opacity-0' : '', 'w-1/6 w-1/6  ' )} aria-hidden="true" />
				</div>



				<div className="relative z-5 flex flex-col justify-between h-full">
					<div className="flex justify-between">
						<div>

						</div>
						<div>
							{selectable && <input
								name={'voice-' + voice.id}
								type="checkbox"
								checked={selected}
								onClick={( e ) => {
									e.stopPropagation()
									e.nativeEvent.stopImmediatePropagation();
								}}
								onChange={( e ) => {
									setSelected( !selected )
								}}
								className="w-6 h-6 text-cobalt-500 border-gray-300 rounded-full focus:ring-indigo-500"
							/>
							}
						</div>
					</div>

					<div className="text-left">
						<h3 className="text-base font-medium text-white">{voice?.name}</h3>
						{voice?.tags.map( ( tag ) => (
							<span className="mr-1 text-xs text-white" key={tag}>{tag}</span>
						) )}
					</div>
				</div>

			</div>
		</button>
	)
}

export default Voice
