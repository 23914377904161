import { useMutation } from 'react-query'
import { useQueryClient } from 'react-query'
import { apiAxios } from 'services/Api'
import { postOrderItem } from 'services/requests/order-items'
import { usePatchOrderPark, usePatchOrderUnpark } from './base'
import { usePostAndAttachOrder } from './orders'

export function usePostOrderItem(orderId) {
	return useMutation<any, unknown, {productVariant: string, quantity: number}>(item => postOrderItem(orderId, item))
}

export const useAddProduct = (order, projectId, setOrderId) => {
	const { mutateAsync: postAndAttachOrder } = usePostAndAttachOrder(projectId);
	const { mutateAsync: createOrderItem } = useCreateOrderItem();
	return useMutation((product) => addProduct(product))

	function addProduct(product) {
		const index = getIndexForNewOrderItem(product)
		if (order && (order.state === 'parked' || order.state === 'cart')) {
			return addOrderItem(product, index, order.code)
		} else {
			return createOrderAndAddOrderItem(product, index)
		}
	}

	function createOrderAndAddOrderItem(product, index = 0,) {
		return postAndAttachOrder().then(order => {
			setOrderId(order.code)
			return addOrderItem(product, index, order.code)
		})
	}

	function addOrderItem(product, index = 0, orderId = '') {
		return createOrderItem({product, orderId, index}, {
			onError: () => createOrderAndAddOrderItem(product, index)
		})
	}

	function getIndexForNewOrderItem(product) {
		let index = 0
		if (!order) return index
		for (let item of order.items) {
			if (item.variant === product.productVariant) {
				const lastInstruction = item.instructions[item.instructions.length - 1]
				index = lastInstruction.payload ? lastInstruction.payload.index + 1 : 0
			}
		}
		return index
	}
}

export const useCreateOrderItem = () => {
	const queryClient = useQueryClient()
	return useMutation<any, unknown, any>(({product, orderId, index}) => createOrderItem(product, orderId, index), {
		onSettled: async data => {
			queryClient.invalidateQueries(['orders', data.orderId])
		}
	})

	function createOrderItem(product, orderId, index) {
		product.instructions.map(instruction => {
			instruction.payload = {...instruction.payload, index}
		})
		product.instructions = [...product.instructions, {
			instruction: '',
			type: 'instruction',
			payload: {type: 'voice', index}
		}]
		return apiAxios.post(`/shop/orders/${orderId}/ftv_add_product`, product).then(result => {
			return Promise.resolve({orderId})
		})
	}
}

const deleteOrderItem = (orderId, item) => {
	if (item.quantity === 1) return apiAxios.delete(`/shop/orders/${orderId}/items/${item.id}`)
	return apiAxios.patch(
		`/shop/orders/${orderId}/items/${item.id}`,
		{ quantity: item.quantity - 1 },
		{ headers: {'Content-Type': 'application/merge-patch+json'} }
	)
}

export const useUpdateOrderItemVoice = (order) => {
	const queryClient = useQueryClient()
	return useMutation<any, unknown, any>(({voice, product, instructionIndex}) => updateVoice(voice, product, instructionIndex), {
		onSettled: async () => {
			queryClient.invalidateQueries(['orders', order.code])
		}
	})

	function updateVoice(voice, product, instructionIndex) {
		const voiceVariant: string = voice ? voice.replace('products', 'product-variants') : ''
		return apiAxios.put(`/shop/orders/${order.code}/ftv_update_product_voice`, {
			instructionIndex: instructionIndex,
			orderItemId: product.id,
			productVariantVoice: voiceVariant
		}).then(() => {
			queryClient.invalidateQueries(['orders', order.code])
			return {orderId: order.code, voice: voiceVariant}
		})
	}
}

export const useRemoveOrderItem = (product, order) => {
	const queryClient = useQueryClient()
	return useMutation<any, unknown, void>(() => deleteOrderItemInstructions(), {
		onSettled: async () => {
			queryClient.invalidateQueries(['orders', order.code])
		}
	})

	function deleteOrderItemInstructions() {
		const instructionIndex = product.instructions[0].payload.index
		return apiAxios.put(`/shop/orders/${order.code}/ftv_delete_product`, {
			orderItemId: product.id,
			instructionIndex
		}).then(() => {
			queryClient.invalidateQueries(['orders', order.code])
			return Promise.resolve({orderId: order.code})
		})
	}
}
